import { Links, Meta, Outlet, Scripts, ScrollRestoration } from 'react-router';
import '@synoptic/ui-kit/normalize';
import { baseTheme, lightTheme } from '@synoptic/ui-kit/theme.css.js';
import { ToastProvider } from '@synoptic/ui-kit/toast/toast-provider.js';
import { makeToastQueue } from '@synoptic/ui-kit/toast/toast-queue.js';
import { Toaster } from '@synoptic/ui-kit/toast/toaster.js';
import { ErrorPage } from './components/error-page/error-page.tsx';
import './entry.css.ts';
import {
  APP_URL,
  AWS_COGNITO_USER_POOL_ENDPOINT,
  AWS_GRAPHQL_API_URL,
  AWS_S3_BUCKET_NAME,
  MEDIA_SERVICE_DOMAIN_NAME,
} from './env.ts';
import { getPageMeta } from './routes-utils/get-page-meta.ts';
import { GlobalProgressBar } from './components/global-progress-bar/global-progress-bar.tsx';
import { TooltipProvider } from '@synoptic/ui-kit/tooltip/tooltip.js';
import {
  getActiveSpan,
  spanToBaggageHeader,
  spanToTraceHeader,
} from '@sentry/aws-serverless';

import { Route } from './+types/root.ts';

const toastQueue = makeToastQueue();

export const ErrorBoundary = () => <ErrorPage withContactUs />;

export const loader = () => {
  const span = getActiveSpan();

  if (!span) return null;

  return {
    traceHeader: spanToTraceHeader(span),
    baggageHeader: spanToBaggageHeader(span),
  };
};

export const clientLoader = () => {};

export const meta = ({ data }: Route.MetaArgs) => {
  const meta = getPageMeta();

  if (data) {
    meta.push({ name: 'sentry-trace', content: data.traceHeader });
    meta.push({ name: 'baggage', content: data.baggageHeader });
  }

  return meta;
};

export const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <html lang="en" className={`${baseTheme} ${lightTheme}`}>
      <head>
        <meta charSet="utf-8" />
        {import.meta.env.MODE !== 'production' ? (
          <meta name="robots" content="noindex, nofollow" />
        ) : null}
        <link rel="icon" href="/static/favicon-v2.ico" sizes="32x32" />
        <link
          id="svgIcon"
          rel="icon"
          href={`/static/icon-${import.meta.env.MODE || 'development'}-v2.svg`}
          type="image/svg+xml"
        />
        <link rel="apple-touch-icon" href="/static/apple-touch-icon-v2.png" />
        <link rel="manifest" href="/static/manifest.json" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,maximum-scale=1,user-scalable=0,viewport-fit=cover"
        />

        <link
          rel="preconnect"
          href="https://cognito-identity.us-east-1.amazonaws.com"
        />
        <link rel="preconnect" href={new URL(AWS_GRAPHQL_API_URL).origin} />
        <link
          rel="preconnect"
          href={new URL(AWS_COGNITO_USER_POOL_ENDPOINT).origin}
        />
        <link rel="preconnect" href={`https://${AWS_S3_BUCKET_NAME}`} />
        <link rel="preconnect" href={`https://${MEDIA_SERVICE_DOMAIN_NAME}`} />

        <Meta />
        <Links />

        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
            const MIXPANEL_CUSTOM_LIB_URL = "${APP_URL}" + "/mp-cdn/lib.min.js";

            (function(f,b){if(!b.__SV){var e,g,i,h;window.mixpanel=b;b._i=[];b.init=function(e,f,c){function g(a,d){var b=d.split(".");2==b.length&&(a=a[b[0]],d=b[1]);a[d]=function(){a.push([d].concat(Array.prototype.slice.call(arguments,0)))}}var a=b;"undefined"!==typeof c?a=b[c]=[]:(c="mixpanel");a.people=a.people||[];a.toString=function(a){var d="mixpanel";"mixpanel"!==c&&(d+="."+c);a||(d+=" (stub)");return d};a.people.toString=function(){return a.toString(1)+".people (stub)"};i="disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking start_batch_senders people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove".split(" ");for(h=0;h<i.length;h++)g(a,i[h]);var j="set set_once union unset remove delete".split(" ");a.get_group=function(){function b(c){d[c]=function(){call2_args=arguments;call2=[c].concat(Array.prototype.slice.call(call2_args,0));a.push([e,call2])}}for(var d={},e=["get_group"].concat(Array.prototype.slice.call(arguments,0)),c=0;c<j.length;c++)b(j[c]);return d};b._i.push([e,f,c])};b.__SV=1.2;e=f.createElement("script");e.type="text/javascript";e.async=!0;e.src=MIXPANEL_CUSTOM_LIB_URL;g=f.getElementsByTagName("script")[0];g.parentNode.insertBefore(e,g)}})(document,window.mixpanel||[]);

            mixpanel.init("${import.meta.env.VITE_MIXPANEL_TOKEN}", {
              api_host: "${APP_URL}",
              api_routes: {
                track: 'mp/t',
                engage: 'mp/e',
                groups: 'mp/g',
              },
              track_pageview: true,
              persistence: 'localStorage',
            })
            `,
          }}
        />
      </head>
      <body>
        <script
          // hack for https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/426
          dangerouslySetInnerHTML={{
            __html: `
          if (global === undefined) {
            var global = window;
          }
          `,
          }}
        ></script>
        <div id="root">
          {/* TODO: probably better to wrap only layouts where it's needed */}
          <GlobalProgressBar />
          <ToastProvider toastQueue={toastQueue}>
            <TooltipProvider delayDuration={500}>{children}</TooltipProvider>
            <Toaster />
          </ToastProvider>

          <ScrollRestoration
            getKey={(location) => {
              const paths = ['/'];

              return paths.includes(location.pathname)
                ? location.pathname
                : location.key;
            }}
          />
          <Scripts />
        </div>
      </body>
    </html>
  );
};

export default function Root() {
  return <Outlet />;
}
