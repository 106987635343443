import { useRef, useState, useEffect } from 'react';
import { useNavigation } from 'react-router';
import { progressBar } from './global-progress-bar.css';
import clsx from 'clsx';

function GlobalProgressBar() {
  const navigation = useNavigation();
  const active = navigation.state !== 'idle';

  const ref = useRef<HTMLDivElement>(null);
  const [animationComplete, setAnimationComplete] = useState(true);

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    if (active) {
      setAnimationComplete(false);
    }

    Promise.allSettled(
      ref.current.getAnimations().map(({ finished }) => finished),
    ).then(() => {
      if (!active) {
        setAnimationComplete(true);
      }
    });
  }, [active]);

  const isCompleted = navigation.state === 'idle' && !animationComplete;
  const isHidden = navigation.state === 'idle' && animationComplete;
  const isLoading = navigation.state === 'loading';
  const isSubmitting = navigation.state === 'submitting';

  return (
    <div
      role="progressbar"
      aria-hidden={!active}
      ref={ref}
      className={clsx(
        progressBar,
        isHidden && 'hidden',
        isSubmitting && 'submitting',
        isLoading && 'loading',
        isCompleted && 'completed',
      )}
    />
  );
}

export { GlobalProgressBar };
